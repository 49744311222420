import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { generateSearchQuery } from '../core/helpers/params-generator';
import { DepositsStatsWidgetRequest } from '../models';

@Injectable({
  providedIn: 'root',
})
export class DepositsStatsService {
  constructor(private http: HttpClient) {}

  load(request: DepositsStatsWidgetRequest) {
    return this.http.get(
      `statistics/payments_totals?${generateSearchQuery(request)}`,
    );
  }
}
