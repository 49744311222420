import { IPagination } from '@app/core/models/api/pagination/pagination.model';
import { IDeposit } from '@app/models/objects';

export interface State {
  isLoading?: boolean;
  error?: any;
  depositData: IDeposit[];
  pagination: IPagination;
}

export const initialState: State = {
  isLoading: false,
  error: null,
  depositData: null,
  pagination: null,
};
