import {
  DepositsStatsWidgetRequest,
  DepositsStatsWidgetResponse,
} from '@app/models';
import { createAction, props } from '@ngrx/store';

export const loadWidgetsRequest = createAction(
  '[Deposits Stats] Load Widgets Request',
  props<{ request: DepositsStatsWidgetRequest }>(),
);

export const loadWidgetsSuccess = createAction(
  '[Deposits Stats] Load Widgets Success',
  props<{ data: DepositsStatsWidgetResponse }>(),
);

export const loadWidgetsFailure = createAction(
  '[Deposits Stats] Load Widgets Failure',
  props<{ error: any }>(),
);

export const reseState = createAction('[Deposits Stats] Reset State');
