import { Action, createReducer, on } from '@ngrx/store';
import { upperFirst } from 'lodash';
import moment from 'moment';

import { floatRound } from '../../helpers/float-round';
import {
  DepositsStatsWidgetResponsePeriod,
  StatsHeaderWidget,
} from '../../models';

import * as fromActions from './actions';
import * as fromState from './state';

const KEYS = ['today', 'yesterday', 'last_week', 'last_month'];

export const reducer = createReducer(
  fromState.initialState,

  on(fromActions.loadWidgetsRequest, (state) => ({
    ...state,
    isLoading: true,
    error: null,
  })),
  on(fromActions.loadWidgetsSuccess, (state, { data }) => {
    const widgets: StatsHeaderWidget[] = KEYS.map((key): StatsHeaderWidget => {
      const interval: DepositsStatsWidgetResponsePeriod = data[key];

      const prefixIcon =
        interval.total.diff_percent >= 0
          ? 'fas fa-arrow-alt-up color--green'
          : 'fas fa-arrow-alt-down color--red';

      const year = new Date().getFullYear() - 1;

      let subtitle: any = {
        key: `year_compare_no_value`,
        params: {
          year,
        },
      };
      if (interval.total.previous > 0) {
        subtitle = {
          key: 'year_compare',
          params: {
            year,
            percent_value: floatRound(interval.total.diff_percent),
          },
          prefixIcon,
        };
      }

      return {
        valueType: 'currency',
        title: {
          key: ['deposits_widgets_titles', key].join('.'),
        },
        count: interval.payments.current,
        value: interval.total.current,
        subtitle,
      };
    });

    return {
      ...state,
      widgets,
      isLoading: false,
      error: null,
    };
  }),
  on(fromActions.loadWidgetsFailure, (state, { error }) => ({
    ...state,
    isLoading: false,
    error,
  })),
  on(fromActions.reseState, () => fromState.initialState),
);

export function depositsStatsReducer(
  state: fromState.State | undefined,
  action: Action,
) {
  return reducer(state, action);
}
