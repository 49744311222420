import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { DepositsStatsStoreEffects } from './effects';
import { depositsStatsReducer } from './reducer';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    StoreModule.forFeature('depositsStats', depositsStatsReducer),
    EffectsModule.forFeature([DepositsStatsStoreEffects]),
  ],
})
export class DepositsStatsStoreModule {}
