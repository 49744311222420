import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { DepositService } from '@app/services/deposit.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { get } from 'lodash';
import { of } from 'rxjs';
import { Observable } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import * as featureActions from './actions';

@Injectable()
export class DepositStoreEffects {
  constructor(
    private dataService: DepositService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  loadRequestEffect$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType<featureActions.LoadRequestAction>(
        featureActions.ActionTypes.LOAD_REQUEST,
      ),

      switchMap((action: featureActions.LoadRequestAction) => {
        return this.dataService.load(action.payload).pipe(
          map(
            (request: any) =>
              new featureActions.LoadSuccessAction({
                items: get(request, 'data.results', []),
                pagination: get(request, 'meta.pagination', []),
              }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(new featureActions.LoadFailureAction({ error }));
          }),
        );
      }),
    ),
  );
}
