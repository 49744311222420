import { IPagination } from '@app/core/models/api/pagination/pagination.model';
import { IDeposit } from '@app/models/objects';
import {
  createFeatureSelector,
  createSelector,
  MemoizedSelector,
} from '@ngrx/store';

import { State } from './state';

export const getError = (state: State): any => state.error;

export const getIsLoading = (state: State): boolean => state.isLoading;

export const getDepositData = (state: State): IDeposit[] => state.depositData;

export const getDepositDataPagination = (state: State): IPagination =>
  state.pagination;

export const selectDepositState: MemoizedSelector<
  object,
  State
> = createFeatureSelector<State>('deposits');

export const selectDepositError: MemoizedSelector<object, any> = createSelector(
  selectDepositState,
  getError,
);

export const selectDepositIsLoading: MemoizedSelector<
  object,
  boolean
> = createSelector(selectDepositState, getIsLoading);

export const selectDepositData: MemoizedSelector<
  object,
  IDeposit[]
> = createSelector(selectDepositState, getDepositData);

export const selectDepositDataPagination: MemoizedSelector<
  object,
  IPagination
> = createSelector(selectDepositState, getDepositDataPagination);
