import { Injectable } from '@angular/core';
import { ErrorHandlerService } from '@app/core/services/error-handler.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { IResponseSuccess } from '../../core/models/response-sucess.model';
import { DepositsStatsWidgetResponse } from '../../models';
import { DepositsStatsService } from '../../services/deposits-stats.service';

import * as fromActions from './actions';

@Injectable()
export class DepositsStatsStoreEffects {
  constructor(
    private dataService: DepositsStatsService,
    private actions$: Actions,
    private errorHandler: ErrorHandlerService,
  ) {}

  load$ = createEffect(() =>
    this.actions$.pipe(
      ofType(fromActions.loadWidgetsRequest),
      switchMap(({ request }) =>
        this.dataService.load(request).pipe(
          map(({ data }: IResponseSuccess<DepositsStatsWidgetResponse>) =>
            fromActions.loadWidgetsSuccess({ data }),
          ),
          catchError((error) => {
            this.errorHandler.handle(error);
            return of(fromActions.loadWidgetsFailure(error));
          }),
        ),
      ),
    ),
  );
}
