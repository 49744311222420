import { IPagination } from '@app/core/models/api/pagination/pagination.model';
import { IDeposit } from '@app/models/objects';
import { Action } from '@ngrx/store';

import { SearchParams } from '../../models/objects/search-params';

export enum ActionTypes {
  LOAD_REQUEST = '[Deposit] Load Request',
  LOAD_FAILURE = '[Deposit] Load Failure',
  LOAD_SUCCESS = '[Deposit] Load Success',

  LOAD_WIDGET_REQUEST = '[Deposit] Load Widget Request',
  LOAD_WIDGET_FAILURE = '[Deposit] Load Widget Failure',
  LOAD_WIDGET_SUCCESS = '[Deposit] Load Widget Success',

  RESET_STATE = '[Deposit] Reset State',
}

export class LoadRequestAction implements Action {
  readonly type = ActionTypes.LOAD_REQUEST;
  constructor(public payload: SearchParams) {}
}

export class LoadFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FAILURE;
  constructor(public payload: { error: string }) {}
}

export class LoadSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_SUCCESS;
  constructor(
    public payload: {
      items: IDeposit[];
      pagination: IPagination;
    },
  ) {}
}

export class ResetSuccessAction implements Action {
  readonly type = ActionTypes.RESET_STATE;
}

export type Actions =
  | LoadRequestAction
  | LoadFailureAction
  | LoadSuccessAction
  | ResetSuccessAction;
